export default {
    LOGIN_SUCCESS: (state, { identity, jwt, roletype, rolename,mail,username }) => {
        state.identity = identity
        state.access_token = jwt
        state.roletype = roletype
        state.rolename = rolename
        state.mail = mail
        state.username = username
        state.error = false
    },
    LOGOUT: state => {
        state.identity = {}
        state.access_token = ''
        state.roletype = ''
        state.rolename = ''
        state.mail = ''
        state.username = ''
        state.error = false
    },
    LOGIN_ERROR: state => {
        state.error = true
    },
    RESET_ERROR: state => {
        state.error = false
    }
}
