import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/'

// Containers
const TheContainer = () =>
    import ('@/containers/TheContainer')

// Views
const Dashboard = () =>
    import ('@/views/Dashboard')
    // Users
const Artigos = () =>
    import ('@/views/biblioteca/artigos/artigos')

const Artigosinterna = () =>
    import ('@/views/biblioteca/artigos/artigosinterna')

const Catalogo = () =>
    import ('@/views/biblioteca/catalogo/catalogo')  

const Catalogointerna = () =>
    import ('@/views/biblioteca/catalogo/catalogointernas')  

const Instrucoes = () =>
    import ('@/views/biblioteca/instrucoes/instrucoes')  

    const Instrucoesinternas = () =>
    import ('@/views/biblioteca/instrucoes/instrucoesinternas')  

const Videosanimacoes = () =>
    import ('@/views/biblioteca/videos/videos')  

    const Videosanimacoesinterna = () =>
    import ('@/views/biblioteca/videos/videosinternas')  

const Webinares = () =>
    import ('@/views/biblioteca/webinares/webinares') 

const Webinaresinterna = () =>
    import ('@/views/biblioteca/webinares/webinaresinternas') 

    
const Login = () =>
    import ('@/views/login/Login')

const Register = () =>
    import ('@/views/register/register')

Vue.use(Router)

const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
})

function configRoutes() {
    return [{
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            component: TheContainer,
            children: [{
                    path: 'dashboard',
                    meta: {
                        title:'Dashboard',
                        label: 'Dashboard'
                    },
                    name: 'Dashboard',
                    component: Artigos
                },
                {
                    path: 'biblioteca',
                    meta: {
                        title:'Biblioteca Impresa',
                        label: 'biblioteca-impressa'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'artigos',
                            meta: {
                                title:'Artigos',
                                label: 'Artigos'
                            },
                            name: 'Artigos',
                            component: Artigos
                        },
                        {
                            path: 'artigos/:id',
                            meta: {
                                title:'Artigos',
                                label: 'Artigos'
                            },
                            name: 'Artigos-interna',
                            component: Artigosinterna
                        },
                        {
                            path: 'catalogos',
                            meta: {
                                title:'Catálogos',
                                label: 'Catálogos'
                            },
                            name: 'Catalogos',
                            component: Catalogo
                        },
                        {
                            path: 'catalogos/:id',
                            meta: {
                                title:'Catálogos',
                                label: 'Catálogos-internas'
                            },
                            name: 'Catalogos-interna',
                            component: Catalogointerna
                        },
                        {
                            path: 'instrucoes-de-uso',
                            meta: {
                                title:'Instruções de Uso',
                                label: 'Instruções de Uso'
                            },
                            name: 'Instrucoes',
                            component: Instrucoes
                        },
                        {
                            path: 'instrucoes-de-uso/:id',
                            meta: {
                                title:'Artigos',
                                label: 'Artigos'
                            },
                            name: 'Intruções-interna',
                            component: Instrucoesinternas
                        },
                        {
                            path: 'videos-e-animacoes',
                            meta: {
                                title:'Vídeos e Animações',
                                label: 'Vídeos e Animações'
                            },
                            name: 'Videos-e-animacoes',
                            component: Videosanimacoes
                        },
                        {
                            path: 'videos-e-animacoes/:id',
                            meta: {
                                title:'Vídeos e animações internas',
                                label: 'Vídeos e animações internas'
                            },
                            name: 'Videos-animacoes-internas',
                            component: Videosanimacoesinterna
                        },
                        {
                            path: 'webinares-e-treinamentos',
                            meta: {
                                title:'Webinares e treinamentos',
                                label: 'Webinares e treinamentos'
                            },
                            name: 'webinares-e-treinamentos',
                            component: Webinares
                        },
                        {
                            path: 'webinares-e-treinamentos/:id',
                            meta: {
                                title:'Webinares e treinamentos internas',
                                label: 'Webinares e treinamentos internas'
                            },
                            name: 'webinares-e-treinamentos-internas',
                            component: Webinaresinterna
                        },
                    ]
                },

            ]
        },
        {
            path: '/login',
            name: 'Login',
            meta: {
                title: 'LAS | Login',
                notAuthorization: true,
                onlyDisconnected: true
            },
            component: Login,
        },
        {
            path: '/cadastro',
            name: 'Cadastro',
            meta: {
                title: 'LAS | Cadastro',
                notAuthorization: true,
                onlyDisconnected: true
            },
            component: Register,
        }

    ]
}

router.beforeEach((to, from, next) => {
    document.title = to.meta.title

    const isNotAuthorization = to.matched.some(
        record => record.meta.notAuthorization
    )
    const isOnlyDisconnected = to.matched.some(
        record => record.meta.onlyDisconnected
    )
    const authenticated = !!store.getters.getToken

    if (!isNotAuthorization && !authenticated) {
        return next('/login')
    }

    if (authenticated && isOnlyDisconnected) {
        return next('/')
    }

    next()
})


export default router