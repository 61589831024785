import axios from 'axios'
import store from '@/store'

const HTTP = axios.create({
    baseURL: process.env.VUE_APP_API_ROOT,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})


const setHeadersAuthorization = () => {
    HTTP.defaults.headers.common[
        'Authorization'
    ] = `Bearer ${store.getters.getToken}`
}



// const setHeadersResponseBLOB = () => {
//     HTTP.defaults.headers.common['Content-Type'] =
//         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
//     HTTP.defaults.headers.common['responseType'] = 'arraybuffer'
// }

export const ApiService = {
    get(path_name) {
        setHeadersAuthorization()
        return HTTP.get(path_name)
    },

    post(path_name, data, config) {
        setHeadersAuthorization()
        return HTTP.post(path_name, data, config)
    },
    postLogin(path_name, data, config) {
        return HTTP.post(path_name, data, config)
    },


    update(path_name,data) {
        setHeadersAuthorization()
        return HTTPUSERINFO.put(path_name, data)
    },

    delete(path_name, info) {
        setHeadersAuthorization()
        return HTTPUSERINFO.delete(`${path_name}/${info}`)
    },


}