import 'core-js/stable'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/index'
import Vuelidate from 'vuelidate'
import { duplicate } from 'brutils1212'

Vue.use(Vuelidate)
Vue.use(VueToast, { position: 'top-right', duration: 5000 })
Vue.use(duplicate)
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App
    }
})