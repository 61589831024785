import jwt_decode from 'jwt-decode'
import { login } from '@/services/login.service.js'

export default {
    async login({ commit }, credentials) {
        try {
            const { identifier, password } = credentials

            const {
                data
            } = await login(identifier, password)
           
            const { jwt } = data
            const roletype = data.user.role.type
            const rolename = data.user.role.name
            const mail = data.user.email
            const username = data.user.username


            const { identity } = jwt_decode(jwt)

            commit('LOGIN_SUCCESS', { identity, jwt, roletype, rolename,mail,username })

            window.location.href = '/'
        } catch (e) {
            console.log(e)
            commit('LOGIN_ERROR')
        }
    },

    logout({ commit }) {
        commit('LOGOUT')
        window.localStorage.removeItem('vuex')
        window.location.href = '/login'
    },

    resetLoginError(context) {
        context.commit('RESET_ERROR')
    }
}